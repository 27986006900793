<template>
  <div class="card-skeleton">
    <div class="skeleton-banner glassy-item">
      <div class="glass-animaton"></div>
    </div>
    <div class="skeleton-body">
      <div class="rating-box mb-3">
        <div class="inner1 glassy-item">
          <div class="glass-animaton"></div>
        </div>
        <div class="inner2">
          <div class="line glassy-item mb-2">
            <div class="glass-animaton"></div>
          </div>
          <div class="line glassy-item">
            <div class="glass-animaton"></div>
          </div>
        </div>
      </div>
      <div class="line-large glassy-item mb-2">
        <div class="glass-animaton"></div>
      </div>
      <div class="line glassy-item mb-2">
        <div class="glass-animaton"></div>
      </div>
      <div class="line-medium glassy-item">
        <div class="glass-animaton"></div>
      </div>
    </div>
  </div>
  <!-- <p>'Cards loading....'</p> -->
</template>
<script>
// import VueContentLoader from 'vue-content-loader';

export default {
  components: {
    // VueContentLoader
  }
};
</script>

<style>
.card-skeleton {
  border: 1px solid #dee2e6;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 16px;
}
.card-skeleton .skeleton-banner {
  height: 250px;
}
.card-skeleton .skeleton-body {
  padding: 16px;
}
.card-skeleton .rating-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.card-skeleton .rating-box .inner1 {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}
.card-skeleton .rating-box .inner2 {
  width: calc(100% - 50px);
  padding-left: 16px;
}
.card-skeleton .glassy-item {
  background-color: #dee2e6;
  overflow: hidden;
}
.card-skeleton .mb-2 {
  margin-bottom: 8px;
}
.card-skeleton .mb-3 {
  margin-bottom: 16px;
}
.card-skeleton .mb-4 {
  margin-bottom: 24px;
}
.card-skeleton .line {
  height: 16px;
}
.card-skeleton .line-medium {
  height: 28px;
}
.card-skeleton .line-large {
  height: 50px;
}
.card-skeleton .glass-animaton {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  animation: glassEffect 1.5s infinite linear;
}
@keyframes glassEffect {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
